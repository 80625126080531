import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  memo,
  ReactNode,
  FC,
} from 'react';

import {
  ApplicationListStateActions,
  ApplicationListStateActionTypes,
} from '../../../actions/applicationList';
import {
  applicationListReducer,
  IApplicationListState,
  initialApplicationListState,
} from '../../../reducers/applicationList';
import { useUserState } from '../UserContext';
import { useMatchingDifferentTermABTest } from '@finanzcheck/ti-shared-ui/hooks/useMatchingDifferentTermABTest';
import { useRDIOfferPageABCTest } from '@finanzcheck/ti-shared-ui/hooks/useRDIOfferPageABCTest';

interface IApplicationListStateContextType {
  applicationList: IApplicationListState;
}

interface IApplicationListDispatchContextType {
  dispatch: React.Dispatch<ApplicationListStateActions>;
}

interface IApplicationListProviderProps {
  children: ReactNode;
}
const ApplicationListStateContext =
  createContext<IApplicationListStateContextType>({
    applicationList: initialApplicationListState,
  });

const ApplicationListDispatchContext =
  createContext<IApplicationListDispatchContextType>({
    dispatch: () => {
      throw new Error('ApplicationListProvider is missing');
    },
  });

const ApplicationListProvider: FC<IApplicationListProviderProps> = ({
  children,
}) => {
  const { variationKey } = useMatchingDifferentTermABTest();
  const { variationKey: variationKeyForRDITest } = useRDIOfferPageABCTest();

  const [applicationList, dispatch] = useReducer(applicationListReducer, {
    ...initialApplicationListState,
    excludeTermAsMatchCriteria: variationKey === 'variation_b',
    excludeRDIAsMatchCriteria: ['variation_b', 'variation_c'].includes(
      variationKeyForRDITest
    ),
  });

  const { user } = useUserState();

  useEffect(() => {
    if (user.signInState === 'SIGNED_OUT') {
      dispatch({
        type: ApplicationListStateActionTypes.APPLICATIONLIST_INVALIDATE_STATE,
      });
    }
  }, [user.signInState]);

  return (
    <ApplicationListDispatchContext.Provider value={{ dispatch }}>
      <ApplicationListStateContext.Provider value={{ applicationList }}>
        {children}
      </ApplicationListStateContext.Provider>
    </ApplicationListDispatchContext.Provider>
  );
};

export default memo(ApplicationListProvider);

export const useApplicationListState = () =>
  useContext(ApplicationListStateContext);

export const useApplicationListDispatch = () =>
  useContext(ApplicationListDispatchContext);
