export enum UserProperty {
  SitespectVariants = 'SitespectVariants',
  IsLoggedIn = 'IsLoggedIn',
  ClientUuid = 'ClientUuid',
  ApplicationUuid = 'applicationUuid',
  TraversalHash = 'traversalHash',
  DeviceTypeCustomAttr = 'DeviceTypeCustomAttr',
}

export enum EventProperty {
  Scope = 'scope',
  CurrentPage = 'currentPage',
}

export enum ABTestVariants {
  UserIsInCompareInterestVariant = 'user_isInCompareInterestVariant', // name the campaign here
  UserInInCheckoutStepsChangeVariant = 'user_isInCheckoutStepsChangeVariant',
}

export enum TrackingEvent {
  PageView = 'pageView',
  CheckoutPageGoToDisbursementCtaClick = 'checkout_page_go_to_disbursement_cta_click',
  OnClickGoToDisbursementFallback = 'button_onClick_goToDisbursement_fallback_fired',
  CheckoutPageCostStructureExpand = 'checkout_page_cost_structure_expand',
  CheckoutPageCostStructureCollapse = 'checkout_page_cost_structure_collapse',
  OnClickPhone = 'button_onClick_phone',
  CheckoutPageContractInformationExpand = 'checkout_page_contract_information_expand',
  CheckoutPageContractInformationCollapse = 'checkout_page_contract_information_collapse',
  OnVisitDocumentsUpload = 'page_onVisit_documentsUploadView',

  DocumentUploadFileAdded = 'document_upload_file_added', //abtest
  DocumentUploadFileDeleted = 'document_upload_file_deleted', //abtest
  DocumentUploadSubmitClicked = 'document_upload_submit_clicked', //abtest
  DocumentUploadFinished = 'document_onUpload_finished', //abtest
  DocumentUploadError = 'document_onUpload_error', //abtest
  //checkout seen

  UploadAreaIncomplete = 'uploadArea_incomplete',
  DocumentAddError = 'document_onAdd_error',
  DocumentDropFile = 'document_onDrop_file',
  DocumentInstructionsOnClose = 'document_instructions_close',
  DocumentInstructionsGoToDacClick = 'document_instructions_go_to_dac_button_click',
  DocumentInstructionsNoDacButtonClikc = 'document_instructions_no_dac_button_click',
  DocumentInstructionsOpenWithHelpIcon = 'document_instructions_open_with_help_icon',
  OnClickShowAddress = 'button_onClick_showAddress',
  OnClickSection = 'button_onClick_section',
  ToggleOpenDebtorSelection = 'toggle_onOpen_debtorSelection',
  ToggleCloseDebtorSelection = 'toggle_onClose_debtorSelection',
  CheckoutPageFurtherQuestionsExpand = 'checkout_page_further_questions_expand',
  CheckoutPageFurtherQuestionsCollapse = 'checkout_page_further_questions_collapse',
  CheckoutPageContractDownloadExpand = 'checkout_page_contract_download_expand',
  CheckoutPageContractDownloadCollapse = 'checkout_page_contract_download_collapse',
  OnClickSendLoanViaEmail = 'button_onClick_sendLoanViaEmail',
  OnClickSendLoanViaPost = 'button_onClick_sendLoanViaPost',
  OfferPageUpsellInformationAmountClickOnAltOffers = 'offer_page_upsell_info_amount_click_on_alt_offers',
  OnClickEditLoan = 'button_onClick_editLoan',
  OnIdentVisible = 'ident_onVisible',
  OnVisitChangeLoanRequest = 'page_onVisit_changeLoanRequest',
  OnRedirectPage = 'link_onRedirect_page',
  OnVisitVideoIdent = 'page_onVisit_videoIdentView',
  OnVisitVideoIdentError = 'page_onVisit_videoIdentError',
  EmbeddedIdentQRCodeScreenShown = 'embedded_ident_qr_code_screen_shown',
  OnClickVideoIdent = 'button_onClick_videoIdent',
  OnSubmitVideoIdentReady = 'button_onSubmit_videoIdentReady',
  OnClickIdentStart = 'ident_onClick_start',
  OnClickIdentCancel = 'ident_onClick_cancel',
  OnClickIdentFailed = 'ident_onClick_failed',
  EmbeddedIdentOpenInNewTabClick = 'embedded_ident_open_in_new_tab_click',
  EmbeddedIdentGoToMobileAppClick = 'embedded_ident_go_to_mobile_app_click',
  OnWebIdPhoneNumberSubmit = 'web_id_phone_number_submit',
  OnWebIdPhoneNumberValidationError = 'web_id_phone_number_validation_error',
  OnFinishIdent = 'ident_onFinish_ident',
  OnViewIdentScreen = 'ident_onView_screen',
  OnRedirectStart = 'link_onRedirect_start',
  OnRedirectError = 'link_onRedirect_error',
  ClickoutIdentStartClick = 'clickout_ident_start_click',
  OnClickBankAppointment = 'button_onClick_callBankForAppointment',
  OnClickBankSendMail = 'link_onClick_writeMailToBank',
  OnClickBankFurtherInformation = 'link_onClick_furtherInformation',
  OnClickSelection = 'button_onClick_selection',
  ToggleOpenTooltip = 'toggle_onOpen_toolTip',
  ToggleCloseTooltip = 'toggle_onClose_toolTip',
  OnClickPhoneNumber = 'link_onClick_phoneNumber',
  OnVisitForwarding = 'page_onVisit_forwarding',
  OnVisitIdentStatus = 'page_onVisit_identStatus',
  OnClickNotGood = 'button_onClick_notGood',
  OnClickOkay = 'button_onClick_okay',
  OnClickSuper = 'button_onClick_super',
  OnVisitCongratulations = 'page_onVisit_congratulationsView',
  ToggleOpenOperator = 'toggle_onOpen_operator',
  ToggleCloseOperator = 'toggle_onClose_operator',
  OnClickOperatorEmail = 'link_onClick_operatorEmail',
  OnClickOperatorPhone = 'link_onClick_operatorPhone',
  OnClickOperatorBookAppointment = 'link_onClick_operatorBookAppointment',
  OnClickAnswerFeedbackPositive = 'button_onClick_answerFeedbackPositive',
  OnClickAnswerFeedbackNegative = 'button_onClick_answerFeedbackNegative',
  DACStateChange = 'dac_state_change',
  DACSuccess = 'dac_success',
  DACError = 'dac_error',
  DACAbort = 'dac_abort',
  CheckoutPageContractDownload = 'checkout_page_contract_download',
  OnClickSendEmail = 'button_onClick_sendEmail',
  CheckoutPageSendPerPostClick = 'checkout_page_send_per_post_click',
  OnClickGoBackToOffers = 'button_onClick_goBackToOffers',
  LoginPageOnError = 'login_page_on_error',
  OnLoginSuccess = 'login_onSuccess',
  OnLogoutSuccess = 'logout_onSuccess',
  LoginStep2ForgotPasswordClick = 'login_step2_forgot_password_click',
  OnClickPasswordVisibility = 'button_onClick_passwordVisibility',
  LoginPageEmailAndPasswordSubmit = 'login_page_email_and_password_submit',
  LoginSuccessWithEmailAndPassword = 'login_success_with_email_and_password',
  VISuccessLoginEmailAndPasswordSubmit = 'vi_success_login_email_and_password_submit',
  OperatorSectionExpand = 'operator_section_expand',
  OperatorSectionCollapse = 'operator_section_collapse',
  OperatorSectionEmailClick = 'operator_section_email_click',
  OperatorSectionPhoneNumberClick = 'operator_section_phone_number_click',
  HeaderLogoClick = 'header_logo_click',
  HeaderMenuFAQClick = 'header_menu_faq_click',
  LoginPageHeaderLoginMenuClick = 'login_page_header_login_menu_click',
  RegistrationPageEmailChange = 'registration_page_email_change',
  ForgotPasswordPageEmailChange = 'forgot_password_page_email_change',
  RegistrationPageSubmitButtonClick = 'registration_page_submit_click',
  ForgotPasswordPageSubmitButtonClick = 'forgot_password_page_submit_click',

  OnClickHeaderMenuItem = 'link_onClick_headerMenuItem',
  OnClickCompleteApplication = 'button_onClick_completeApplication',
  OnClickShowOffers = 'button_onClick_showOffers',
  OnClickResumeTraversal = 'button_onClick_resumeTraversal',
  OnClickLoadMoreTraversals = 'button_onClick_loadMoreTraversals',
  OnClickPreContractualInfo = 'button_onClick_preContractualInfo',
  ToggleOpenHelpAccordion = 'toggle_onOpen_helpAccordion',
  ToggleCloseHelpAccordion = 'toggle_onClose_helpAccordion',
  OnClickGoToServiceCenter = 'button_onClick_goToServiceCenter',
  OnClickGoToTopQuestion = 'link_onClick_goToTopQuestion',
  OfferPageOnClickSortApplication = 'offer_page_on_click_sort_application',
  ServiceCenterOnSearch = 'serviceCenter_onSearch',
  ServiceCenterOnSelectTag = 'serviceCenter_onSelect_tag',
  ServiceCenterOnSelectTopic = 'serviceCenter_onSelect_topic',
  ServiceCenterOnSelectQuestion = 'serviceCenter_onSelect_question',
  ServiceCenterOnSelectSearchResult = 'serviceCenter_onSelect_searchResult',
  OfferPageSearchAmountValidate = 'offer_page_search_amount_validate',
  OfferPageSearchAmountChange = 'offer_page_search_amount_change',
  OfferPageSearchTermChange = 'offer_page_search_term_change',
  OfferPageSearchRDIChange = 'offer_page_search_rdi_change',
  OfferPageSearchSecondaryDebtorAdd = 'offer_page_search_secondary_debtor_add',
  OfferPageSearchButtonClick = 'offer_page_search_button_click',
  OfferPageInstantDecisionOfferShown = 'offer_page_instant_decision_offer_shown',
  OfferPageOffersCount = 'offer_page_offers_count',
  RecommendedOfferToCOSeen = 'recommended_offer_to_checkout_seen ',
  BestOfferToCOSeen = 'best_offer_to_checkout_seen ',
  CheapestOfferToCOSeen = 'cheapest_offer_to_checkout_seen ',

  OnVisitSecondDebtorInformation = 'page_onVisit_secondDebtorInformaton',
  NoOfferPageAddSecondBorrowerClick = 'no_offer_page_add_second_borrower_click',
  OnVisitInterestIndicator = 'element_onVisit_interestIndicator',
  OnCloseOfflineModal = 'modal_onClose_offline',
  OnShowOfflineModal = 'modal_onShow_offline',
  OnVisitPromotion = 'page_onVisit_promotion',
  OnVisitOfferView = 'page_onVisit_offerView',
  OnVisitApplicationList = 'page_onVisit_applicationsList',
  OnClickBacklink = 'button_onClick_backlink',
  OnVisitLoading = 'page_onVisit_loading',
  OnCheckLoadingAnswer = 'loading_onCheck_Answer',
  OnVisitUnknownThankYou = 'page_onVisit_unknownThankYou',
  UserIsInMyTestCampaign = 'user_is_in_my_test_campaign',

  HandleUnknownIdentEvent = 'ident_receive_unknownEvent',
  LoginPageRegistrationButtonClick = 'login_page_registration_button_click',
  LoginPageEmailSubmit = 'login_page_email_submit',
  VISuccessLoginPageEmailSubmitClick = 'vi_success_login_page_email_submit_click',
  OnClickRegistration = 'link_onClick_registration',
  OnClickMagicLoginSent = 'link_onClick_magicLoginSent',
  OnClickEmailSubmit = 'button_onClick_emailSubmit',
  UserIsInCustomerAreaAAVariant = 'user_isInCustomerAreaAAVariant',
  RedirectionShown = 'redirection_shown',

  AllFinalLead = 'all_final_lead',
  QLead = 'q_lead',
  FinalNoOfferPageShown = 'final_no_offer_page_shown',
  FinaCheckoutPageShown = 'final_checkout_page_shown',

  DashboardPageShown = 'dashboard_page_shown',
  HomeOfferPageShown = 'home_offer_page_shown',
  NoOffersPageShown = 'no_offers_page_shown',
  HomeChangeLoanRequestSearchClick = 'home_change_loan_request_search_click',
  HomeCheckoutPageShown = 'home_checkout_page_shown',
  HomeDACExpanded = 'home_dac_expanded',
  ApplicationRendered = 'application_rendered',

  NoAffiliateApplicationRendered = 'no_aff_application_rendered',
  NoAffiliateNoOffersPageShown = 'no_aff_no_offers_page_shown',
  NoAffiliateQLead = 'no_aff_q_lead',
  NoAffiliateFinaCheckoutPageShown = 'no_aff_final_checkout_page_shown',
  NoAffiliateOnClickOffersToCheckoutFinal = 'no_aff_offer_to_checkout_click_final',

  OnGenerateSupportCodeMenuClick = 'generate_support_code_menu_click',
  SupportCodeDialogShown = 'support_code_dialog_shown',
  OnCloseSupportCodeDialog = 'support_code_dialog_close',
  OnSupportConsentDialogShown = 'support_consent_dialog_shown',
  OnClickSupportConsentDialogAgree = 'support_consent_dialog_agree_click',
  OnClickSupportConsentDialogDisAgree = 'support_consent_dialog_disAgree_click',
  OnGenericSupportErrorDialogShow = 'generic_support_error_dialog_shown',
  OnGenericSupportErrorDialogClose = 'generic_support_error_dialog_close',
  OnSupportActiveSessionStarted = 'support_active_session_started',
  OnClickSupportActiveSessionEnd = 'support_active_session_end_click',

  // Optimizely Events!
  OnClickDashboardToOffersHome = 'dashboard_to_offers_click_home',
  OnClickOffersToCheckoutHome = 'offer_to_checkout_click_home',
  OnClickOffersToCheckoutFinal = 'offer_to_checkout_click_final',
  OnClickStartDocumentUploadHome = 'start_document_upload_click_home',
  OnClickStartDocumentUploadFinal = 'start_document_upload_click_final',
  OnClickEmbeddedIdentStart = 'embedded_ident_start_click',
  OnClickOffersToCheckout = 'offer_to_checkout_click',

  //CoBrowse Survey
  ShowSurveyModal = 'survey_modal_shown',
  OnClickProceedWithSurvey = 'proceed_with_survey_click',
  OnClickCloseSurveyModal = 'survey_modal_close_click',

  FinalKronosInitialRequestGenericError = 'final_kronos_initial_request_generic_error',
  FinalKronosInitialRequestLastRetryError = 'final_kronos_initial_request_last_retry_error',
  FinalKronosAppDelegatorError = 'final_kronos_app_delegator_error',

  //ViSuccess screen
  ShowViSuccessLoginScreen = 'vi_success_login_screen_shown',

  //Magic Login
  MagicLoginSentSuccess = 'magic_login_sent',
  MagicLoginSentErrorDuplicateCustomer = 'magic_login_sent_error_duplicate_customer',
  MagicLoginSentErrorGeneric = 'magic_login_sent_error_generic',

  LoginScreenShownOriginFromViDone = 'login_screen_shown_origin_from_vi_done',
  LoginSuccessOriginFromViDone = 'login_success_origin_from_vi_done',
  LoginFailOriginFromViDone = 'login_fail_origin_from_vi_done',

  //Magic Token login screen
  LoginScreenShownOriginFromMagicToken = 'login_screen_shown_origin_from_magic_token',
  LoginSuccessOriginFromMagicToken = 'login_success_origin_from_magic_token',
  LoginFailOriginFromMagicToken = 'login_fail_origin_from_magic_token',

  //Normal Login
  LoginSuccessOriginNormalLogin = 'login_success_origin_from_normal_login',
  LoginFailOriginNormalLogin = 'login_fail_origin_from_normal_login',

  //E-Mail
  EmailCheckoutRequested = 'email_checkout_requested',
  EmailCheckoutFailed = 'email_checkout_failed',

  //Upsell save now badge
  UpsellSaveNowBadgeShown = 'upsell_save_now_badge_shown',
  UpsellSaveNowBadgeExplanationTooltipShown = 'upsell_save_now_badge_explanation_tooltip_shown',

  // Offer Cards + Search bar AB test tracking events
  DashboardNoOfTraversalPerCustomer = 'dashboard_no_of_traversal_per_customer',
  TimeToFirstOffer = 'time_to_first_offer',
  TimeToNoOfferPage = 'time_to_no_offer_page',
  InitialTimeSpentOnOfferPage = 'initial_time_spent_on_offer_page',

  DACReuseInfoError = 'dac_reuse_info_error',
  DACReuseInfoLoaded = 'dac_reuse_info_loaded',
  DACReuseAccepted = 'dac_reuse_accepted',
  DACReuseDeclined = 'dac_reuse_declined',
  DACReuseNotFound = 'dac_reuse_not_found',
  DACReuseEdit = 'dac_reuse_edit',

  //Offer details
  OfferDetailsToCheckoutClick = 'offer_details_to_checkout_click',
  CheapestOfferDetailsToCheckoutClick = 'cheapest_offer_to_checkout_click',
  BestOfferDetailsToCheckoutClick = 'best_offer_to_checkout_click',
  ClickToViewOfferDetails = 'click_to_view_offer_details',
  OfferDetailsSeen = 'offer_details_seen',

  // Checkout
  CheckoutStarted = 'teal.checkout.v1.CheckoutStarted',

  // Multipage Checkout
  CheckoutTabswitch = 'checkout_tabswitch',
  CheckoutButtonSeen = 'checkout_button_seen',
  SaveButtonClick = 'save_button_click',
  DocumentsSentPerPostClick = 'documents_sent_per_post_click',
  CheckoutSuccessSeen = 'checkout_success_seen',
  DacOnDocumentUploadSuccess = 'document_upload_dac_success',
  IdentificationCompletedOfflineClick = 'identification_completed_offline_click',
  VIFirstScreenCtaClick = 'vi_firstscreen_cta_click',
  IdentConfirmationMessageYesClick = 'identification_confirmation_yes_click',
  IdentConfirmationMessageNoClick = 'identification_confirmation_no_click',

  // Mobile APP promotion banners
  MobileBannerShown = 'mobile_banner_shown',
  MobileBannerDownloadClick = 'mobile_banner_download_click',
  MobileBannerOpenAppViewShown = 'mobile_banner_open_app_view_shown',
  MobileBannerOpenAppButtonClick = 'mobile_banner_open_app_button_click',
  MobileBannerClose = 'mobile_banner_close',

  AllDocumentsUploaded = 'all_documents_uploaded',
  IdentStepFinishedByOnline = 'ident_step_finished_by_online',
  HelpAndSupportMenuClick = 'help_and_support_menu_click',
  DocumentUploadNextStepClicked = 'document_upload_next_step_clicked',
  DocumentUploadChangeTabToIdentClicked = 'document_upload_change_tab_to_ident_clicked',

  // COMMON FOR ALL IDENT PROVIDERS
  OverlayOpened = 'open_overlay',
  OverlayShown = 'overlay_shown',
  OverlayClosed = 'overlay_closed',
  PhoneNumberEntered = 'phone_number_entered',
  EmailEntered = 'email_entered',
  ContactValidationError = 'contact_validation_error',
  StartIdentClicked = 'start_ident_clicked',
  DownloadAppButtonClicked = 'downloadapp_button_clicked',
  ContinueBrowserButtonClicked = 'continuebrowser_button_clicked',
  TransactionScreenShown = 'transaction_screen_shown',
  TransactionButtonClicked = 'transaction_button_clicked',

  // WEBID
  LegalTextChecked = 'legal_text_checked',

  // IDNow
  StartIdentClickedToOpenLegalTextOverlay = 'start_ident_clicked_to_open_legal_text_overlay',
  LegalTextOverlayShown = 'legal_text_overlay_shown',

  //POSTIDENT
  ConfirmationButtonClicked = 'confirmation_button_clicked',
  ContactInfoConfirmed = 'contact_info_confirmed',

  // OFFER PAGE SORTING
  OnChangeSortInOfferPage = 'onchange_sort_in_offer_page',

  // OFFERS LOADING SCREEN DROP
  OnCloseBrowserAtOffersLoadingScreen = 'on_close_browser_at_offers_loading_screen',
}
