export enum Feature {
  NEW_HEADER = 'NEW_HEADER',
  FINAL_WITHOUT_IFRAME = 'FINAL_WITHOUT_IFRAME',
  CO_BROWSE = 'CO_BROWSE',
  NEW_OFFER_PAGE_UPDATES = 'NEW_OFFER_PAGE_UPDATES',
  NEW_CHECKOUT = 'NEW_CHECKOUT',
  SMAVA_MOBILE_FAKEDOOR_TEST = 'SMAVA_MOBILE_FAKEDOOR_TEST',
  MISSING_SALARY_SLIPS_UI = 'MISSING_SALARY_SLIPS_UI',
}

type ActiveFeatureMap = {
  [key in Feature]: boolean;
};

const mapActiveFeatures: ActiveFeatureMap = {
  [Feature.NEW_HEADER]: true,
  [Feature.FINAL_WITHOUT_IFRAME]: true,
  [Feature.CO_BROWSE]: false,
  [Feature.NEW_OFFER_PAGE_UPDATES]:
    import.meta.env.VITE_NEW_OFFER_PAGE_UPDATES_ENABLED === 'true',
  [Feature.NEW_CHECKOUT]: import.meta.env.VITE_NEW_CHECKOUT_ENABLED === 'true',
  [Feature.SMAVA_MOBILE_FAKEDOOR_TEST]: true,
  [Feature.MISSING_SALARY_SLIPS_UI]:
    import.meta.env.VITE_MISSING_SALARY_SLIPS_UI === 'true',
};

export const isFeatureActive = (feature: Feature): boolean => {
  return mapActiveFeatures[feature] || false;
};
