import { memo } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { RouteSwitch } from './RouteSwitch';
import ClientProvider from '@finanzcheck/ti-shared-ui/components/Context/Client';
import OfferDetailsProvider from '@finanzcheck/ti-shared-ui/components/OfferPage.experiment.NewOfferPage/OfferCardDetails/context';
import { MainTheme } from './Theme';
import { DynamicHead } from './DynamicHead';
import TwoStepLoginProvider from './Context/Login';
import { clientAdapter } from '../adapter/clientAdapter';
import { SitespectProvider } from './Context/Sitespect';
import { Projects } from '@finanzcheck/ti-shared-ui/constants/domain/projects';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /**
       * We need to handle the "isLoading" state a bit differently.
       * We cannot "throw away" our component state and render skeletons if "isLoading" is triggered by a refetch.
       * We need to show an alternative loader or a loading overlay (without removing the current component state).
       */
      refetchOnWindowFocus: false,
    },
  },
});

export const HomeApp = memo(() => (
  <QueryClientProvider client={queryClient}>
    <ClientProvider clientAdapter={clientAdapter} project={Projects.HOME}>
      <TwoStepLoginProvider>
        <BrowserRouter>
          <DynamicHead />
          <MainTheme>
            <OfferDetailsProvider>
              <SitespectProvider>
                <RouteSwitch />
              </SitespectProvider>
            </OfferDetailsProvider>
          </MainTheme>
        </BrowserRouter>
      </TwoStepLoginProvider>
    </ClientProvider>
  </QueryClientProvider>
));
