import { useContext } from 'react';
import { TenantDomain, getCurrentTenantConfig } from '../utils/tenant';
import { Experiments } from '../utils/optimizely/experiments';
import {
  useDecision as nativeUseDecision,
  OptimizelyContext,
} from '@optimizely/react-sdk';

export const useMatchingDifferentTermABTest = () => {
  const { domain } = getCurrentTenantConfig();
  const isFinanzcheck = domain === TenantDomain.Finanzcheck;
  const { optimizely } = useContext(OptimizelyContext);

  // We are calling the useDecision hook conditionally to avoid errors on console if app is not wrapped inside optimizely provider(affiliate cases!)
  const decision =
    optimizely &&
    nativeUseDecision(
      Experiments.PLAYERS_EXCLUDE_TERM_IN_MATCHING_CRITERIA_AB_TEST
    );

  // We roll out the variation_b for Finanzcheck
  if (isFinanzcheck) {
    return {
      variationKey: 'variation_b',
    };
  }

  return {
    variationKey:
      decision && decision?.[0]?.variationKey
        ? decision?.[0]?.variationKey
        : 'variation_a',
  };
};
