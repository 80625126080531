import { PropsWithChildren, memo, useState } from 'react';
import { useUserState } from '../UserContext';
import { TrackContext } from '@finanzcheck/ti-shared-ui/hooks/useTrack';

export const TrackProvider: React.FC<PropsWithChildren> = memo(
  ({ children }) => {
    const [applicationUuid, setApplicationUuid] = useState<
      string | undefined
    >();
    const { user } = useUserState();
    return (
      <TrackContext.Provider
        value={{
          signInState: user.signInState,
          applicationUuid,
          setApplicationUuid: setApplicationUuid,
        }}
      >
        {children}
      </TrackContext.Provider>
    );
  }
);
